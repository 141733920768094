import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../Grades/GradeListingTable.module.scss';
import { PROFESSIONAL_PLAN } from '../../../constants/User';
import { ElementBoxes } from '.';
import clsx from 'clsx';
import { getDateSpectro } from './MobileSpectroList';
import { useNavigate } from 'react-router-dom';
import { COMMON_ACTION_TYPES } from '../../../redux/commonActionTypes';
import { SPECTRO_CONSTS } from '../../../constants/Spectrometer';
import { isEafFurnace } from '../../../utils/Grade';
import { sendAnalytics } from '../../../utils/analyticsUtils';
import { Button } from 'aui-component-library';

const chargeMixSchema = (userPlan, analyticsKey) =>
  userPlan === PROFESSIONAL_PLAN
    ? [
        {
          header: 'CM ID / Name',
          id: 'cm',
          data: row => row,
          cell: d => <ChargeMixSchema data={d} analyticsKey={analyticsKey} />,
          align: 'left',
          width: '11%'
        }
      ]
    : [];

export const getSpectroTableSchema = () => {
  const { analyticsKey, userDetail } = useSelector(state => state);
  const { plan: userPlan } = userDetail;
  return [
    {
      header: 'Heat No.',
      id: 'sku',
      data: row => row,
      cell: d => <HeatId data={d} />,
      align: 'left',
      width: '7%'
    },
    ...chargeMixSchema(userPlan, analyticsKey),
    {
      header: 'Grade / Code',
      id: 'grade',
      data: row => row,
      cell: d => <GradeCode data={d} />,
      align: 'left',
      width: '10%'
    },
    {
      header: 'Part Name / No.',
      id: 'partname',
      data: row => row.cm_data,
      cell: d => <PartNameNo data={d} />,
      align: 'left',
      width: '12%'
    },
    {
      header: 'Type',
      id: 'type',
      data: row => row,
      cell: d => <ReadingType data={d} />,
      align: 'center',
      width: '5%'
    },
    {
      header: 'Spectrometer Readings',
      id: 'spectrometerReadings',
      data: row => row,
      cell: d => <SpectroReadings data={d} />,
      align: 'left',
      width: '40%'
    },
    {
      header: 'Date & Time',
      id: 'date',
      data: row => row,
      cell: d => <DateTime data={d} analyticsKey={analyticsKey} />,
      align: 'left',
      width: '10%'
    }
  ];
};

const HeatId = ({ data }) => (
  <div className={styles.smallText}>
    {data?.heat_name ? (
      `${data?.heat_name?.slice(0, 40)}`
    ) : (
      <p style={{ color: '#909090' }}>Not Found!</p>
    )}
  </div>
);

const GradeCode = ({ data }) => (
  <div>
    <div className={styles.smallText}>{data?.grade_name?.slice(0, 20)} </div>
    <div className={styles.smallText}>{data?.grade_code?.slice(0, 20)} </div>
  </div>
);

const PartNameNo = ({ data }) => (
  <>
    <div className={clsx(styles.smallText, styles.ellipsisText)}>
      {data?.part_name}
    </div>
    <div className={clsx(styles.smallText, styles.ellipsisText)}>
      {data?.part_no}
    </div>
  </>
);

const ReadingType = ({ data }) => {
  const {
    GRADE_TYPE_BATH,
    GRADE_TYPE_FINAL,
    GRADE_TYPE_PIT,
    GRADE_TYPE_ADDITION,
    GRADE_TYPE_LADLE
  } = SPECTRO_CONSTS;
  return (
    <div className={styles.tagReadingContainer}>
      <div className={styles.tagContainer}>
        {data.readings.map((item, index) => {
          const readingTypeClassName =
            item.sample_type == GRADE_TYPE_BATH ||
            item.sample_type == GRADE_TYPE_LADLE ||
            item.sample_type == GRADE_TYPE_ADDITION
              ? styles.bathSampleType
              : item.sample_type == GRADE_TYPE_FINAL ||
                item.sample_type == GRADE_TYPE_PIT
              ? styles.finalSampleType
              : styles.nullSampleType;
          return (
            <div
              key={index}
              className={clsx(styles.spectroReadingTag, readingTypeClassName)}
            >
              {item.sample_type}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SpectroReadings = ({ data: { readings, cm_data } }) => {
  const elementRef = useRef();
  const furnace_type = useSelector(
    state => state.userDetail?.customer?.furnace_type
  );
  const targetChemistry = isEafFurnace(furnace_type)
    ? cm_data?.cm_pit_target_chemistry
    : cm_data?.cm_target_chemistry;
  return (
    <div className={styles.tagContainer}>
      {readings.map((item, index) => {
        return (
          <div ref={elementRef} key={index}>
            <ElementBoxes
              ref={elementRef}
              elements={item.reading_avg}
              tc={targetChemistry}
            />
          </div>
        );
      })}
    </div>
  );
};

const DateTime = ({ data, analyticsKey }) => (
  <div className={styles.dateTimeContainer}>
    <div className={clsx(styles.tagContainer, styles.latestDate)}>
      {data.readings.map((item, index) => {
        return (
          <div className={styles.smallTextDate} key={index}>
            {getDateSpectro(item.reading_created_at)}
          </div>
        );
      })}
    </div>

    <div
      onClick={() => {
        sendAnalytics({
          defaultName: `${analyticsKey}_View`,
          defaultDetails: `View Spectrometer reading : ${data.id}`
        });
      }}
      className={styles.viewBtn}
    >
      <Button
        text='View'
        variant='secondary'
        icon='right-arrow'
        dir='rtl'
        weight='bold'
      />
    </div>
  </div>
);

const ChargeMixSchema = ({ data, analyticsKey }) => {
  const navigate = useNavigate();
  const handleClick = event => {
    sendAnalytics({
      defaultName: `${analyticsKey}_MeltingFurnace`,
      defaultDetails: `Navigate to Melting Furnace`
    });
    event.stopPropagation();
    const url =
      data.cm_data?.id && data.cm_data?.cm_tag_id
        ? `/cm/chargemix/wizard/${data.cm_data?.id}?page=${COMMON_ACTION_TYPES.FINAL_OUTPUT}`
        : `${data.id}`;

    data.cm_data?.id ? window.open(url, '_blank') : navigate(url);
  };

  return (
    <div className={styles.smallText} onClick={handleClick}>
      {data.cm_data?.cm_tag_id}{' '}
      {data.cm_data?.cm_tag_id && (
        <i className={clsx('ri-share-box-line', styles.cmRedirectIcon)}></i>
      )}
      <div className={styles.nameTagContainer}>{data.cm_data?.cm_name}</div>{' '}
    </div>
  );
};
