export const GRADE_LIST_ACTION_TYPES = {
  GET_GRADE_LIST_SUCCESS: 'GET_GRADE_LIST_SUCCESS',
  UPDATE_GRADE_LIST_SUCCESS: 'UPDATE_GRADE_LIST_SUCCESS',
  GET_GRADE_DETAIL_SUCCESS: 'GET_GRADE_DETAIL_SUCCESS',
  ADD_GRADE_NEXT_STEP_TOGGLE: 'ADD_GRADE_NEXT_STEP',
  ADD_GRADE_BASIC_STEP_DATA_UPDATE: 'ADD_GRADE_BASIC_STEP_DATA_UPDATE',
  ADD_GRADE_INVENTORY_ITEM_STEP_DATA_UPDATE:
    'ADD_GRADE_INVENTORY_ITEM_STEP_DATA_UPDATE',
  ADD_GRADE_CHARGEMIX_ITEM_STEP_DATA_UPDATE:
    'ADD_GRADE_CHARGEMIX_ITEM_STEP_DATA_UPDATE'
};
