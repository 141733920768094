import { Chip, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import {
  getCmList_v2,
  getGradeCodeList_v2,
  getGradeList_v2,
  getISOTime,
  getSampleType
} from '../../../utils/Spectrometer';
import classes from './SpectroListingTable.module.scss';
import CustomAutocomplete from '../../General/CustomAutocomplete';
import { getPartNames, getPartNumbers } from '../../../api/spectroApi';
import { useSelector } from 'react-redux';
import { SPECTRO_CONSTS } from '../../../constants/Spectrometer';
import { handleDateChange } from '../../../utils';
import { Button } from 'aui-component-library';

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: 400,
    backgroundColor: theme.palette.background.default
  },
  inputBox: {
    width: '100%',
    color: 'black'
  }
}));

const FilterDrawer = ({ handleClose, applyFilter, query, setQuery }) => {
  const filterInitialValue = {
    readings__sample_type: '' || query.readings__sample_type,
    heat_date__lte: '' || query.heat_date__lte,
    heat_date__gte: '' || query.heat_date__gte,
    grade: '' || query.grade,
    grade__grade_code: query.grade__grade_code,
    cm: '' || query.cm,
    grade__part_name: '' || query.grade__part_name,
    grade__part_no: '' || query.grade__part_no
  };

  const { START_DATE, END_DATE } = SPECTRO_CONSTS;

  const userDetail = useSelector(state => state.userDetail);

  const sampleTypeOptions = getSampleType(userDetail?.customer?.furnace_type);

  const [data, setData] = useState(filterInitialValue);
  const [text, setText] = useState('');
  const [chargeText, setChargeText] = useState('');
  const [partNameText, setPartNameText] = useState('');
  const [partNumberText, setPartNumberText] = useState('');
  const [gradeCode, setGradeCode] = useState('');

  const muiStyles = useStyles();

  useEffect(() => {
    setData(query);
  }, []);

  function applyFilter() {
    const api_params = {
      ...query,
      readings__sample_type:
        data.readings__sample_type !== 'NULL'
          ? data.readings__sample_type
          : undefined,
      heat_date__lte: data.heat_date__lte ?? undefined,
      heat_date__gte: data.heat_date__gte ?? undefined,
      grade: data.grade ?? undefined,
      grade__grade_code: data.grade__grade_code ?? undefined,
      cm: data.cm,
      grade__part_name: data.grade__part_name || undefined,
      grade__part_no: data.grade__part_no || undefined
    };
    const filtered_params = Object.fromEntries(
      Object.entries(api_params).filter(([key, value]) => value !== undefined)
    );
    setQuery(filtered_params);
    handleClose();
  }

  function resetFilter() {
    const blankData = {
      ...query,
      heat_date__lte: '',
      heat_date__gte: '',
      grade: '',
      grade__grade_code: '',
      cm: '',
      readings__sample_type: '',
      grade__part_name: '',
      grade__part_no: ''
    };
    setQuery(blankData);
    setData(blankData);
    setChargeText('');
    setText('');
    setPartNameText('');
    setPartNumberText('');
    setGradeCode('');
    handleClose();
  }

  const handleChange = (type, value) => {
    const date_range = handleDateChange(type, value, data);
    setData(prev => ({ ...prev, ...date_range }));
  };

  return (
    <div>
      <Button
        variant='text'
        icon='cancel'
        onClick={handleClose}
        className={classes.closeButton}
      />
      <div className={classes.filterHeader}>Filters</div>
      <div className={classes.horizontalLine} />
      <div className={classes.filterBody}>
        {/* {Object.keys(query).length > 0 && (
          <>
            <div className={classes.chipContainer}>
              {Object.keys(query).map(d => (
                <Chip
                  label={`${d} : ${query[d]}`}
                  onDelete={() => {
                    const updatedQuery = { ...query };
                    delete updatedQuery[d];
                    setQuery(updatedQuery);
                  }}
                  key={d}
                />
              ))}
            </div>
            <div className={classes.horizontalLine} />
          </>
        )} */}

        <div className={classes.filterSubHeader}>Date Range</div>
        <div className={classes.dateRangeInput}>
          <input
            type='date'
            className={classes.inputbox_date}
            max={getISOTime()}
            value={data.heat_date__gte}
            onChange={e => handleChange(START_DATE, e.target.value)}
          />
          <span className={classes.spaceHifen}>-</span>
          <input
            type='date'
            className={classes.inputbox_date}
            max={getISOTime()}
            value={data.heat_date__lte}
            onChange={e => handleChange(END_DATE, e.target.value)}
          />
        </div>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Sample Type</div>
        <div className={classes.selectWrapper}>
          <select
            placeholder='Sample Type'
            id='outlined-basic'
            value={data.readings__sample_type}
            className={classes.selectBox}
            onChange={e =>
              setData({ ...data, readings__sample_type: e.target.value })
            }
          >
            {sampleTypeOptions.map(({ label, value }) => (
              <option key={label} value={value || label}>
                {label}
              </option>
            ))}
          </select>
          <div className={classes.arrow}>
            <i class='ri-arrow-down-s-line'></i>
          </div>
        </div>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Charge Mix</div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, cm: value?.id })}
              handleChange={value => setChargeText(value)}
              userInput={chargeText}
              placeholderText='Select the chargemix'
              label='Chargemix Name'
              nameIndentifier={'cm_name'}
              callbackFunc={getCmList_v2}
              apiQuery={chargeText}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Grade </div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, grade: value?.id })}
              setUserInput={value => setText(value)}
              userInput={text}
              placeholderText='Select a Metal Grade'
              label='Metal grade'
              nameIndentifier={'name'}
              callbackFunc={getGradeList_v2}
              handleChange={value => setText(value)}
              apiQuery={text}
              deSelectItem={value => setData({ ...data, grade: '' })}
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Grade Code</div>
        <FormControl fullWidth>
          <label style={{ width: '100%' }}>
            <CustomAutocomplete
              onSelect={value => setData({ ...data, grade__grade_code: value })}
              setUserInput={value => setGradeCode(value)}
              userInput={gradeCode}
              placeholderText='Select a Metal Grade Code'
              label='Grade Code'
              callbackFunc={getGradeCodeList_v2}
              handleChange={value => setGradeCode(value)}
              apiQuery={gradeCode}
              deSelectItem={value =>
                setData({ ...data, grade__grade_code: '' })
              }
            />
          </label>
        </FormControl>
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Part Name</div>
        <CustomAutocomplete
          onSelect={value => setData({ ...data, grade__part_name: value })}
          setUserInput={value => setPartNameText(value)}
          userInput={partNameText}
          placeholderText='Select a Part Name'
          label='Part Name'
          callbackFunc={getPartNames}
          handleChange={value => setPartNameText(value)}
          apiQuery={partNameText}
          deSelectItem={value => setData({ ...data, grade__part_name: '' })}
        />
        <div className={classes.horizontalLine} />
        <div className={classes.filterSubHeader}>Part Number</div>
        <CustomAutocomplete
          onSelect={value => setData({ ...data, grade__part_no: value })}
          setUserInput={value => setPartNumberText(value)}
          userInput={partNumberText}
          placeholderText='Select a Part Number'
          label='Part Number'
          callbackFunc={getPartNumbers}
          handleChange={value => setPartNumberText(value)}
          apiQuery={partNumberText}
          deSelectItem={value => setData({ ...data, grade__part_no: '' })}
        />
        <div className={classes.filterBtnContainer}>
          <Button
            variant='outlined'
            onClick={resetFilter}
            icon='cancel'
            text='RESET'
          />
          <Button onClick={applyFilter} icon='check' text='APPLY' />
        </div>
      </div>
    </div>
  );
};

export default FilterDrawer;
