import { GRADE_LIST_ACTION_TYPES } from './gradeActionType';
import initialState from '../initial-state';

/**
 * Reducer function for the grade list state slice.
 *
 * @param {Array} state - The current state of the grade list.
 * @param {object} action - The action to be applied to the state.
 * @returns {Array} - The updated state of the grade list.
 */
export function gradeList(state = initialState.gradeList, action) {
  switch (action.type) {
    case GRADE_LIST_ACTION_TYPES.GET_GRADE_LIST_SUCCESS:
      return action.gradeList;
    case GRADE_LIST_ACTION_TYPES.UPDATE_GRADE_LIST_SUCCESS:
      return state.map(grade => {
        if (grade.id === action.updatedGrade.id) {
          return {
            ...grade,
            ...action.updatedGrade
          };
        }
        return grade;
      });
    default:
      return state;
  }
}
// GET_GRADE_DETAIL_SUCCESS gradeDetail
/**
 * Reducer function for the grade detail state slice.
 *
 * @param {Array} state - The current state of the grade detail.
 * @param {object} action - The action to be applied to the state.
 * @returns {Array} - The updated state of the grade list.
 */
export function gradeDetail(state = initialState.gradeDetail, action) {
  switch (action.type) {
    case GRADE_LIST_ACTION_TYPES.GET_GRADE_DETAIL_SUCCESS:
      return action.gradeDetail;
    default:
      return state;
  }
}

export const addGradeCurrentStep = (
  state = initialState.addGradeCurrentStep,
  action
) => {
  switch (action.type) {
    case 'SET_CURRENT_STEP':
      return action.payload;
    default:
      return state;
  }
};

export const addGradeStepData = (
  state = initialState.addGradeStepData,
  action
) => {
  switch (action.type) {
    case GRADE_LIST_ACTION_TYPES.ADD_GRADE_BASIC_STEP_DATA_UPDATE:
      return {
        ...state,
        basic_data: action.payload
      };
    case GRADE_LIST_ACTION_TYPES.ADD_GRADE_INVENTORY_ITEM_STEP_DATA_UPDATE:
      return {
        ...state,
        grade_item: action.payload
      };
    case GRADE_LIST_ACTION_TYPES.ADD_GRADE_CHARGEMIX_ITEM_STEP_DATA_UPDATE:
      return {
        ...state,
        charge_mix_item: action.payload
      };
    default:
      return state;
  }
};
