export const addPlanSteps = [
  'Enter Plan Details',
  'Chargemix',
  'Verify Details'
];

export const addPlanStepCount = {
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3
};

export const rawMaterialFields = [
  {
    name: 'raw_material',
    label: 'Product',
    placeholder: 'Choose Product'
  },
  {
    name: 'quantity',
    label: 'Quantity (in Kg)',
    placeholder: 'Enter Qty.'
  }
];

export const qtyTabs = ['KG', 'TON'];

export const rawMaterialInitData = {
  raw_material: {
    id: '',
    name: ''
  },
  quantity: ''
};

export const castingPlanInitialData = {
  part_name: {
    id: '',
    name: ''
  },
  part_no: {
    id: '',
    number: ''
  },
  no_of_moulds: ''
};

export const addPlanFormInitalData = {
  grade: '',
  shift: '',
  tapping_min: '',
  tapping_max: '',
  planned_date: new Date().toISOString().split('T')[0],
  uom_furnace: 'KG',
  uom_additives: 'KG',
  uom_ladle: 'KG',
  uom_nodularizer: 'KG',
  production_casting_plans: [castingPlanInitialData],
  production_raw_materials: {
    furnace: [rawMaterialInitData],
    additives: [rawMaterialInitData],
    ladle: [rawMaterialInitData],
    nodularizer: [rawMaterialInitData]
  }
};

export const STATUS_CONSTANTS = {
  ONGOING: 'ONGOING',
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED'
};

export const heatCategories = [
  {
    label: 'All Plans',
    value: 'ALL',
    color: 'rgb(21, 121, 190)'
  },
  {
    label: 'Completed',
    value: 'COMPLETED',
    color: 'rgb(43, 162, 76)'
  },
  {
    label: 'Ongoing',
    value: 'ONGOING',
    color: 'rgb(213, 140, 0)'
  },
  {
    label: 'Pending',
    value: 'PENDING',
    color: 'rgb(228, 62, 43)'
  }
];
