import {
  Backdrop,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getChargeMixElements,
  getInventoryItemSuggestion
} from '../../redux/actions/chargeMix';
import styles from './AddDilModal.module.scss';
import { getChargeMix } from '../../api/chargeMixApi';
import { toast } from 'react-toastify';
import CustomAutocomplete from '../../componentsV2/General/CustomAutocomplete';
import {
  getCmList,
  getGradeDetails,
  getGradeList,
  postSpectroReading
} from '../../utils/Spectrometer';
import { Button } from 'aui-component-library';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  padding: '20px 25px',
  borderRadius: '8px'
};

const AddDilModal = ({
  open,
  handleClose,
  cm_elements,
  getChargeMixElements,
  isAdminView,
  userDetail
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [overviewData, setOverViewData] = useState({
    ...(userDetail === 'BASIC' && { grade: '' }),
    heat_name: '',
    spectro_reading: [],
    type: 'BATH',
    ...(userDetail.plan !== 'BASIC' && { charge_mix: null }),
    furnace_size: 1200,
    buffer: '',
    liquid_metal_weight: '',
    datetime: new Date().toISOString()
  });
  const [heat_date, setDate] = useState(
    new Date(Date.now() + 5.5 * 60 * 60 * 1000).toISOString().substr(0, 10)
  );
  const [time, setTime] = useState(
    new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
  );
  const [chargeApiQuery, setChargeApiQuery] = useState({
    isAdminView,
    apiParams: {
      is_optimized: true,
      limit: 10,
      page: 1,
      search: ''
    }
  });
  useEffect(() => {
    if (!cm_elements) {
      getChargeMixElements();
    }
  }, [cm_elements]);

  useEffect(() => {
    if (overviewData.grade && !overviewData.charge_mix) {
      getGradeDetails(overviewData.grade).then(data => {
        const tcApiData = data.grade_tc.map(ele => ({
          element: { ...elementIdBySym(ele.element) }
        }));
        setOverViewData(overviewData => {
          return {
            ...overviewData,
            spectro_reading: tcApiData.map(ele => {
              return { ...ele?.element, value: '' };
            })
          };
        });
      });
    }
  }, [overviewData.grade]);

  useEffect(() => {
    if (overviewData.charge_mix) {
      getChargeMix(overviewData.charge_mix)
        .then(res => {
          setOverViewData(overviewData => {
            return {
              ...overviewData,
              spectro_reading: res.cm_target_chemistry.map(ele => {
                return { symbol: ele?.element__symbol, value: '' };
              })
            };
          });
          return null;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [overviewData.charge_mix]);

  function handlChargemixSearchChange(value) {
    setChargeApiQuery({
      ...chargeApiQuery,
      apiParams: { ...chargeApiQuery.apiParams, search: value }
    });
  }

  function handleMetalGradeSearchChange(value) {
    setSearchQuery(value);
  }

  function handleChargeMixChange(value) {
    setOverViewData(overviewData => ({
      ...overviewData,
      charge_mix: value?.id
    }));
  }

  const handleOverviewDataChange = ({ name, value }) => {
    setOverViewData(d => ({ ...d, [name]: value }));
  };
  useEffect(() => {
    setOverViewData(overviewData => ({
      ...overviewData,
      datetime: new Date().toISOString()
    }));
  }, [heat_date, time]);
  const handleMetalGradeSelect = value => {
    if (value) {
      setOverViewData(d => ({
        ...d,
        grade: value?.id,
        cm_target_chemistry: value?.grade_tc
      }));
    }
  };
  const elementIdBySym = id => {
    const res = cm_elements.filter(ele => ele.id == id);
    return res[0];
  };
  async function saveData() {
    if (
      overviewData.buffer === '' ||
      overviewData.liquid_metal_weight === '' ||
      overviewData.heat_name === '' ||
      overviewData.spectro_reading.filter(ele => ele.value).length ===
        overviewData.spectro_reading ||
      (userDetail.plan !== 'BASIC' && overviewData.charge_mix === null) ||
      (userDetail.plan === 'BASIC' && overviewData.grade === '')
    ) {
      toast.error('Please fill all the fields');
    } else {
      await postSpectroReading(overviewData);
      handleClose();
    }
  }
  return (
    <div>
      <Modal
        open={open}
        onClose={null}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className={styles.modalDiv}>
              <div
                className={styles.header_fix}
                style={{
                  background: 'white'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <div style={{ fontWeight: '700', fontSize: '16px' }}>
                    Add Spectrometer Reading
                  </div>
                  <div
                    onClick={() => handleClose()}
                    style={{ cursor: 'pointer', float: 'right' }}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_169_18780)'>
                        <path
                          d='M11.9997 10.5862L16.9497 5.63623L18.3637 7.05023L13.4137 12.0002L18.3637 16.9502L16.9497 18.3642L11.9997 13.4142L7.04974 18.3642L5.63574 16.9502L10.5857 12.0002L5.63574 7.05023L7.04974 5.63623L11.9997 10.5862Z'
                          fill='#909090'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_169_18780'>
                          <rect width='24' height='24' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>
              <div className={styles.threeGrid}>
                <TextField
                  variant='outlined'
                  label='Liquid Metal Weight'
                  value={overviewData.liquid_metal_weight}
                  required
                  type='number'
                  onChange={e =>
                    handleOverviewDataChange({
                      name: 'liquid_metal_weight',
                      value: e.target.value
                    })
                  }
                />
                <TextField
                  variant='outlined'
                  name='buffer'
                  label='Furnace Buffer'
                  type='number'
                  value={overviewData.buffer}
                  required
                  onChange={e =>
                    handleOverviewDataChange({
                      name: 'buffer',
                      value: e.target.value
                    })
                  }
                />
                <TextField
                  variant='outlined'
                  label='Heat Name'
                  value={overviewData.heat_name}
                  required
                  onChange={e =>
                    handleOverviewDataChange({
                      name: 'heat_name',
                      value: e.target.value
                    })
                  }
                />
              </div>
              <div className={styles.autoCompleteContainer}>
                <div>
                  <label className={styles.input_label} htmlFor='date-input'>
                    Select heat date
                  </label>
                  <input
                    type='date'
                    className={styles.inputbox_date}
                    max={new Date(Date.now() + 5.5 * 60 * 60 * 1000)
                      .toISOString()
                      .substr(0, 10)}
                    value={heat_date}
                    onChange={e => setDate(e.target.value)}
                  />
                </div>
                <div>
                  <label className={styles.input_label} htmlFor='date-input'>
                    Select heat tine
                  </label>
                  <input
                    type='time'
                    className={styles.inputbox_date}
                    value={time}
                    max={new Date().toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                    onChange={e => setTime(e.target.value)}
                  />
                </div>
              </div>

              <div className={styles.autoCompleteContainer}>
                <TextField
                  variant='outlined'
                  label='Furnace Size'
                  value={overviewData.furnace_size}
                  required
                  type='number'
                  onChange={e =>
                    handleOverviewDataChange({
                      name: 'furnace_size',
                      value: e.target.value
                    })
                  }
                />
                <div>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Reading Type
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={overviewData.type}
                      label='Reading Type'
                      onChange={e =>
                        setOverViewData({
                          ...overviewData,
                          type: e.target.value
                        })
                      }
                    >
                      <MenuItem value={'NULL'}>None</MenuItem>
                      <MenuItem value={'FINAL'}>Final</MenuItem>
                      <MenuItem value={'BATH'}>Bath</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className={styles.autoCompleteContainer}>
                {userDetail.plan !== 'BASIC' ? (
                  <div className={styles.autoCompleteForm}>
                    <label style={{ width: '100%' }}>
                      <div className={styles.input_label}>
                        Select Your Charge Mix
                      </div>
                      <CustomAutocomplete
                        onSelect={handleChargeMixChange}
                        handleChange={handlChargemixSearchChange}
                        userInput={chargeApiQuery.apiParams.search}
                        placeholderText='Select the chargemix'
                        label='Chargemix Name'
                        nameIndentifier={'cm_name'}
                        callbackFunc={getCmList}
                        apiQuery={chargeApiQuery.apiParams.search}
                      />
                    </label>
                  </div>
                ) : (
                  <div className={styles.autoCompleteForm}>
                    <label style={{ width: '100%' }}>
                      <div className={styles.input_label}>
                        Select your Metal Grade
                      </div>
                      <CustomAutocomplete
                        onSelect={handleMetalGradeSelect}
                        setUserInput={value => setSearchQuery(value)}
                        userInput={searchQuery}
                        placeholderText='Select a Metal Grade'
                        label='Metal grade'
                        nameIndentifier={'name'}
                        callbackFunc={getGradeList}
                        handleChange={handleMetalGradeSearchChange}
                        apiQuery={searchQuery}
                      />
                    </label>
                  </div>
                )}
              </div>
              <div className={styles.elementContainer}>
                {overviewData.spectro_reading.map((item, index) => (
                  <div key={index} className={styles.elementBox}>
                    {item.symbol}
                    <TextField
                      variant='standard'
                      required={true}
                      size='small'
                      type='number'
                      value={item.rate}
                      inputProps={{ style: { textAlign: 'center' } }}
                      sx={{ padding: '0px 10px' }}
                      onChange={e => {
                        const updatedOverviewData = { ...overviewData };
                        const spectroReadingIndex =
                          updatedOverviewData.spectro_reading.findIndex(
                            element => element.symbol === item.symbol
                          );
                        updatedOverviewData.spectro_reading[
                          spectroReadingIndex
                        ].value = e.target.value;
                        setOverViewData(updatedOverviewData);
                      }}
                      defaultValue={item == 'Mg' ? '0' : ''}
                      disabled={item == 'Mg'}
                    />
                  </div>
                ))}
              </div>
              <div className={styles.btnContainerNew}>
                <Button text='Save' onClick={saveData} />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cm_elements: state.chargeMix.cm_elements.data,
    isAdminView: state.adminView,
    userDetail: state.userDetail
  };
}

const mapDispatchToProps = {
  getChargeMixElements,
  getInventoryItemSuggestion
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDilModal);
