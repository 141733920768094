export const BASIC_STEP = 'basic_step';
export const INVENTORY_ITEM = 'inventory_item';
export const CHARGEMIX_ITEM = 'charge_mix_item';
export const CHARGEMIX_STEP = 'charge_mix_step';
export const DEFAULT_PAGE_SIZE = 150;
export const rmType = [
  'All Items',
  'Furnace',
  'Additives',
  'Ladle',
  'Nodularizer'
];

export const DI_ID = 1;
export const ADI_ID = 2;
export const GCI_ID = 3;

export const COMMON_KEYS = {
  losses: '',
  min: '',
  relaxed_min: '',
  max: '',
  relaxed_max: ''
};

export const DEFAULT_GCI_TC = [
  {
    name: 'Carbon',
    symbol: 'C',
    element: 1
  },
  {
    name: 'Silicon',
    symbol: 'Si',
    element: 2
  }
];
export const DEFAULT_DI_TC = [
  ...DEFAULT_GCI_TC,
  {
    name: 'Magnesium',
    symbol: 'Mg',
    element: 6
  },
  {
    name: 'Sulphur',
    symbol: 'S',
    element: 3
  }
];

export const gradeViewTabList = [
  'Target Chemistry',
  'Tolerance',
  'Raw Material'
];
