export const PART_LIST_ACTION_TYPES = {
  GET_PART_LIST_SUCCESS: 'GET_PART_LIST_SUCCESS',
  UPDATE_PART_LIST_SUCCESS: 'UPDATE_PART_LIST_SUCCESS',
  GET_PART_DETAIL_SUCCESS: 'GET_PART_DETAIL_SUCCESS',
  ADD_PART_NEXT_STEP_TOGGLE: 'ADD_PART_NEXT_STEP',
  ADD_PART_BASIC_STEP_DATA_UPDATE: 'ADD_PART_BASIC_STEP_DATA_UPDATE',
  ADD_PART_INVENTORY_ITEM_STEP_DATA_UPDATE:
    'ADD_PART_INVENTORY_ITEM_STEP_DATA_UPDATE',
  ADD_PART_CHARGEMIX_STEP_DATA_UPDATE: 'ADD_PART_CHARGEMIX_STEP_DATA_UPDATE'
};
